import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalContactSuite from '@solid-ui-blocks/Educabot/Education/Modal/Block01/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import HeroOne from '@solid-ui-blocks/Educabot/Education/Hero/Block03'
import Simplify from '@solid-ui-blocks/Educabot/Education/Hero/simplify'
import WhyChooseUs from '@solid-ui-blocks/Educabot/Education/Features/features'
import Contact from '@solid-ui-blocks/Educabot/Education/Contact'
import favicon from '../../../../../../site/content/assets/favicon.png'

import Centralizes from '@solid-ui-blocks/Educabot/Education/Hero/centralize'
import Organize from '@solid-ui-blocks/Educabot/Education/Hero/organize'
import Incorporate from '@solid-ui-blocks/Educabot/Education/Hero/incorporate'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalContactSuite content={content['contact-suite']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.firstParagraph}>
        <p>Te ofrecemos un ecosistema completo de herramientas online para tus clases</p>
      </Box>
      <Box sx={styles.firstSections}>
        <HeroOne content={content['heroOneSuite']} reverse />
      </Box>
      <Box sx={styles.sections}>
        <Text sx={styles.title}>Conocé más sobre Suite de aplicaciones</Text>
        <Centralizes content={content['centralizes']} />
      </Box>
      <Box sx={styles.sections}>
        <Organize content={content['organize']} reverse />
      </Box>
      <Box sx={styles.sections}>
        <Incorporate content={content['incorporate']} />
      </Box>
      <Box sx={styles.simplify}>
        <Simplify content={content['simplify']} reverse />
      </Box>
      <Box sx={styles.whyChooseUs}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Box>
      <Box sx={styles.contact}>
        <Contact content={content['formContactSolutions']} reverse />
      </Box>
        <Footer content={content['footer']} />


    </Layout>
  )
}

export const query = graphql`
  query solutionsEducationBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
