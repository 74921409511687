/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

 import bgContact from '../../../../../../site/content/assets/flexiblocks/homepage/education/bg-contact.svg'

 export default {
   header: {
     bg: '#27A8FF',
     pt: '1rem',
   },
   contact: {
     bg: '#27A8FF',
     backgroundImage: `url(${bgContact})`,
     backgroundRepeat: `no-repeat`,
     backgroundPosition: `center`,
     backgroundSize: 'contain',
     '@media (max-width: 765px)': {
       backgroundSize: 'cover',
       paddingBottom: '3rem',
     },
     '@media (max-width: 625px)': {
        paddingBottom: '8rem',
      },
      '@media (max-width: 485px)': {
        paddingBottom: '11rem',
      }
   },
   firstSections: {
    bg: '#FFFFFF',
    pt: '2rem'
  },
  firstParagraph: {
    bg: '#FFFFFF',
    color: '#2A205E',
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    padding: '40px 0 20px 0',
    '& p': {
        maxWidth: 315,
        margin: 'auto',
    },
    '@media (min-width: 1024px)': {
        display: 'none',
    }
  },
   sections: {
     bg: '#FFFFFF',
     pt: '5rem',
     '@media (max-width: 991px)': {
      pt: '2rem'
    }
   },
   whyChooseUs: {
     bg: '#FFFFFF',
     pt: '2rem',
     pb: '5rem'
   },
   simplify: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '2rem',
     '@media (max-width: 991px)': {
      pt: '1rem'
    }
   },
   features: {
     bg: '#FFFFFF',
     pt: '1rem',
     paddingBottom: '5rem'
   },
   testimonials: {
     bg: '#FFFFFF',
     pt: '8rem'
   },
   trustUs: {
     bg: '#F7F8FC',
     pt: '5rem',
     pb: '5rem'
   },
   blog: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '5rem'
   },
   title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    textAlign: 'center',
    pt: '1rem',
    paddingBottom: '3rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'center',
      padding: '5px'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
 }